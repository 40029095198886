<template>
    <v-container fluid style="padding:0px; background-color: #FFEBC0">
        <v-row no-gutters>
            <v-col cols="12" xs="6" sm="6" md="2_5" align="center">
                <v-row no-gutters class="first-row">
                    <v-col class="room-first-row" cols="12" align-self="center" style="background-color: #6B2EB9; cursor:pointer" @click="OpenActivity('6a6b5c5d9e9', 'Earth and Space')">
                        <v-img :src="require(`@/assets/earth-space-icon_1.png`)" contain></v-img>
                    </v-col>
                </v-row>
                <v-row no-gutters class="second-row">
                    <v-col cols="12" align-self="center">
                        <v-icon class="back-icon" @click="$router.push({ name: 'Home' })">
                            west
                        </v-icon>
                    </v-col>
                </v-row>
                <v-row no-gutters class="third-row" style="background-color: #007540">
                    <v-col cols="12" align-self="center" class="room-third-row">
                        <v-img :src="require(`@/assets/living-organisms-icon_1.png`)" contain>
                            
                        </v-img>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="2_5" align="center">
                <v-row no-gutters class="first-row" style="background-color: #E76600">
                    <v-col cols="12" align-self="center" class="room-first-row">
                        <v-img :src="require(`@/assets/energy-icon_1.png`)" contain>
                            
                        </v-img>
                    </v-col>
                </v-row>
                <v-row no-gutters class="second-row">
                    <v-col cols="12" align-self="center">
                        
                    </v-col>
                </v-row>
                <v-row no-gutters class="third-row" style="background-color: #1D72EF">
                    <v-col cols="12" align-self="center" class="room-third-row">
                        <v-img :src="require(`@/assets/weather-icon_1.png`)" contain>
                            
                        </v-img>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" xs="0" sm="0" md="2_5" align="center">
                <v-row no-gutters class="large-column">
                    <v-col cols="12" align-self="center">
                        <v-img :src="require(`@/assets/2b-Happy-talk.png`)" contain class="TWO_B">
                            
                        </v-img>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="2_5" align="center">
                <v-row no-gutters class="first-row" style="background-color: #EE252F">
                    <v-col cols="12" align-self="center" class="room-first-row">
                        <v-img :src="require(`@/assets/forces-icon_1.png`)" contain>
                            
                        </v-img>
                    </v-col>
                </v-row>
                <v-row no-gutters class="second-row">
                    <v-col cols="12" align-self="center">

                    </v-col>
                </v-row>
                <v-row no-gutters class="third-row" style="background-color: #CC8F00">
                    <v-col cols="12" align-self="center" class="room-third-row">
                        <v-img :src="require(`@/assets/weather-icon-2_1.png`)" contain>
                            
                        </v-img>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="2_5" align="center">
                <v-row no-gutters class="first-row" style="background-color: #F0529D">
                    <v-col cols="12" align-self="center" class="room-first-row">
                        <v-img :src="require(`@/assets/matter-icon_1.png`)" contain>
                            
                        </v-img>
                    </v-col>
                </v-row>
                <v-row no-gutters class="second-row">
                    <v-col cols="12" align-self="center">
                        
                    </v-col>
                </v-row>
                <v-row no-gutters class="third-row" style="background-color: #00A47F">
                    <v-col cols="12" align-self="center" class="room-third-row">
                        <v-img :src="require(`@/assets/diversity-icon_1.png`)" contain>
                            
                        </v-img>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: 'Rooms', 
    data(){
        return{
        }
    },
    computed:{
        
    },
    methods:{
        OpenActivity(Room_id, Room_name){
             this.$router.push({ name: 'Lessons', params: { id: Room_id, name: Room_name, slug: Room_name.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-') } })
        }
    }
}
</script>
<style scoped>

.first-row .v-image, .third-row .v-image{
    width: 100%;
    height: inherit;
    transition: all 0.3s;
}
.first-row .v-image:hover, .third-row .v-image:hover{
    -webkit-filter: drop-shadow(5px 5px 5px #000);
    filter: drop-shadow(5px 5px 5px #000);
}
.first-row .col, .third-row .col{
    padding: 15px !important;
    height: 100%;
    width: 100%;
}
.TWO_B {
  position: relative;
  transition: transform 0.3s;
  /* animation: mymove 5s infinite;
  animation-timing-function: linear; */
}

.room-first-row{
    border-bottom: 5px solid;
    border-image:  linear-gradient(to right, black 33%, #FFEBC0 33%, #FFEBC0 67%, black 67%) 1;
    border-left: 3px solid black;
    border-right: 3px solid black;
}

.room-third-row{
    border-top: 5px solid;
    border-image:  linear-gradient(to left, black 33%, #FFEBC0 33%, #FFEBC0 67%, black 67%) 1;
    border-left: 3px solid black;
    border-right: 3px solid black;
}

.TWO_B:hover{
    transform: scale(1.1);
}
.back-btn, .back-icon{
    color: #fff;
    font-size: 46px;
    transition: all 0.3s;
    cursor: pointer;
    -webkit-text-stroke: 1px black;
}
.back-btn:hover, .back-icon:hover{
    text-shadow: 2px 2px 2px rgba(255,255,255,0.5);
    color: #6FC41E;
}
/* @keyframes mymove {
  0%    {top: 0px;}
  25%   {top: 100px;}
  75%   {top: -100px;}
  100%   {top: 0px;}
} */

@media screen and (max-width: 767px){
    .first-row, .third-row{
        height: calc(20vh);
    }
    .second-row{
        display: none;
    }
    .large-column{
        height: calc(20vh);
    }
    .large-column .v-image{
        height: calc(20vh);
    }
}
@media screen and (max-width: 959px) and (min-width: 768px){
    .col-md-2_5{
        /*width: calc(1vw) !important;*/
        flex: 0 0 calc(19.55vw);
        max-width: calc(19.55vw);
    }
}
@media screen and (max-width: 1363px) and (min-width: 960px){
    .col-md-2_5{
        /*width: calc(1vw) !important;*/
        flex: 0 0 calc(19.64vw);
        max-width: calc(19.64vw);
    }
}
@media screen and (max-width: 1904px) and (min-width: 1364px){
    .col-md-2_5{
        /*width: calc(1vw) !important;*/
        flex: 0 0 calc(19.64vw);
        max-width: calc(19.64vw);
    }
}
@media screen and (min-width: 1905px){
    .col-md-2_5{
        /*width: calc(1vw) !important;*/
        flex: 0 0 calc(19.82vw);
        max-width: calc(19.82vw);
    }
}
@media screen and (min-width: 768px){
    .first-row, .third-row{
        height: calc(40vh);
    }
    .second-row{
        height: calc(20vh);
    }
    .large-column{
        height: calc(100vh);
    }
    .large-column .v-image{
        height: calc(40vh);
    }
}
</style>